.mainRequest {
  background: #248594;
  color: white;
  max-width: 700px;
  margin: 40px auto;
  border-radius: 15px;
  padding: 30px 20px;
}

.titleLog {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}

.inputDiv {
  width: 100%;
  background-color: white;
  border-radius: 7px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  position: relative;
}

.inputDiv input {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 7px;
  outline: 0px;
  border: 0px;
  font-size: 17px;
  font-weight: 600;
}

.inputDiv img {
  width: 25px;
}

.pin {
  width: 20px !important;
}

.inputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 15px 0px;
}

.inputGrid .inputDiv {
  margin: 0px;
}

.buttonReq {
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-weight: 700;
  font-size: 19px;
  margin-top: 20px;
}

.cancelReq {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: white;
  font-weight: 600;
  font-size: 19px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px !important;
}

@media (max-width: 460px) {
  .inputGrid {
    grid-template-columns: 1fr;
  }
}

.categoryFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
}

.categoryFlex button {
  background: transparent;
  border: 0px;
  outline: 0px;
  color: white;
  padding: 8px;
  font-size: 23px;
  font-weight: 600;
}

.buttonActive {
  border-bottom: 2px solid rgb(219, 219, 0) !important;
}

.inputDiv a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #248594;
  color: white;
  border: 0px;
  outline: 0px;
  padding: 0px 6px;
  height: 30px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 17px;
}

.or {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.notAccount {
  font-weight: 600;
  font-size: 19px;
  text-align: center;
}

.notAccount a {
  color: rgb(219, 219, 0);
}

.hidden {
  display: none;
}
