.anoy-logo {
  width: 140px;
  margin: 0 auto;
}

.about-main-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  gap: 30px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.maintext-about div {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: 30px;
}
.maintext-about {
  text-align: center;
}

.maintext-about span {
  font-size: 20px;
  max-width: 500px;
  font-weight: 500;
}

video {
  width: 100%;
  margin-top: 30px;
}
