.mainRequest {
  background: #248594;
  color: white;
  max-width: 700px;
  margin: 40px auto;
  border-radius: 15px;
  padding: 20px;
}

hidden {
  display: none;
}
.titleReq {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
}

.inputDiv {
  width: 100%;
  background-color: white;
  border-radius: 7px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
}

.inputDiv input{
  color: black;

} 
.inputDiv input,
select {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 7px;
  outline: 0px;
  border: 0px;
  font-size: 17px;
  font-weight: 600;
  color: black !important;

}

select option{
  color: black !important;

}

.inputDiv img {
  width: 25px;
}

.pin {
  width: 20px !important;
}

.inputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 15px 0px;
}

.inputGrid .inputDiv {
  margin: 0px;
}

.buttonReq {
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-weight: 700;
  font-size: 19px;
  margin-top: 20px;
}

.attachResume {
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.486);
  color: white;
  font-weight: 700;
  font-size: 19px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.cancelReq {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: white;
  font-weight: 600;
  font-size: 19px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px !important;
}

@media (max-width: 460px) {
  .inputGrid {
    grid-template-columns: 1fr;
  }
}

.checkFlex {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 600;
  font-size: 17px;
  margin-top: 20px;
}

.checkFlex input {
  width: 25px;
  height: 25px;
  transform: translateY(5px);
  cursor: pointer;
  accent-color: rgb(219, 219, 0);
}

.checkFlex label {
  cursor: pointer;
}

.checkFlex a {
  text-decoration: underline;
}
